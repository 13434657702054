@import '../sass/variables';

.drawer {
  padding-top: $appbar-height;
  height: 100vh;
}

.link {
  position: relative;
  // padding-left: 20px !important;

  &__active {
    background-color: rgba($color: #000000, $alpha: 0.1) !important;
    color: $color-primary !important;

    span {
      font-weight: bold !important;
    }

    svg {
      color: $color-primary !important;
    }

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.15) !important;
    }

    &::after {
      content: '';
      position: absolute;
      width: 4px;
      background-color: $color-primary;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
