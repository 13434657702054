@import '../sass/variables';

.login {
  height: 100vh;

  .image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .content {
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    .form {
      width: 100%; // Fix IE 11 issue.
      margin-top: 10px;
      text-align: right;
    }

    .submit {
      margin: 30px 0;
    }
  }
}
