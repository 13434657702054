@import '../sass/variables';

.main {
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  padding-top: $appbar-height;
  display: flex;
  flex-direction: column;
}
