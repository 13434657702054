@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Odibee+Sans&display=swap);
.Appbar_appbar__s3RET{color:white;border-bottom:1px solid #cccccc}.Appbar_link__T727j{position:relative}.Appbar_link__active__3o0qc{background-color:rgba(0,0,0,0.1) !important;color:#23b5d3 !important}.Appbar_link__active__3o0qc span{font-weight:bold !important}.Appbar_link__active__3o0qc svg{color:#23b5d3 !important}.Appbar_link__active__3o0qc:hover{background-color:rgba(0,0,0,0.15) !important}.Appbar_link__active__3o0qc::after{content:'';position:absolute;width:4px;background-color:#23b5d3;height:100%;left:0;top:0}

.Drawer_drawer__4MheF{padding-top:48px;height:100vh}.Drawer_link__3U0Y-{position:relative}.Drawer_link__active__1ifRQ{background-color:rgba(0,0,0,0.1) !important;color:#23b5d3 !important}.Drawer_link__active__1ifRQ span{font-weight:bold !important}.Drawer_link__active__1ifRQ svg{color:#23b5d3 !important}.Drawer_link__active__1ifRQ:hover{background-color:rgba(0,0,0,0.15) !important}.Drawer_link__active__1ifRQ::after{content:'';position:absolute;width:4px;background-color:#23b5d3;height:100%;left:0;top:0}

.Dashboard_main__1duYX{flex-grow:1;height:100vh;overflow:auto;padding-top:48px;display:flex;flex-direction:column}

.Login_login__1WSYo{height:100vh}.Login_login__1WSYo .Login_image__1S60U{background-repeat:no-repeat;background-size:cover;background-position:center}.Login_login__1WSYo .Login_content__1WbFh{margin:20px 10px;display:flex;flex-direction:column;align-items:center;align-self:center}.Login_login__1WSYo .Login_content__1WbFh .Login_form__JTcik{width:100%;margin-top:10px;text-align:right}.Login_login__1WSYo .Login_content__1WbFh .Login_submit__1B25t{margin:30px 0}

body{overflow:hidden}#root{display:flex}.firebase-emulator-warning{display:none}.column{display:flex;flex-direction:column}.column__center{align-items:center}.MuiDialog-paperScrollBody{vertical-align:top !important}.MuiAlert-action{align-items:flex-start !important}.MuiChip-label{font-family:'Montserrat', sans-serif}.Toastify__toast{font-family:'Montserrat', sans-serif;animation-duration:350ms}.Toastify__toast-container{width:100%;max-width:450px}.Toastify__toast--info{background-color:#006987}.Toastify__toast--success{background-color:#4caf50}

