@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Odibee+Sans&display=swap');

body {
  overflow: hidden;
}

#root {
  display: flex;
}

.firebase-emulator-warning {
  display: none;
}

.column {
  display: flex;
  flex-direction: column;

  &__center {
    align-items: center;
  }
}

.MuiDialog-paperScrollBody {
  vertical-align: top !important;
}

.MuiAlert-action {
  align-items: flex-start !important;
}

.MuiChip-label {
  font-family: 'Montserrat', sans-serif;
}

.Toastify__toast {
  font-family: 'Montserrat', sans-serif;
  animation-duration: 350ms;
}

.Toastify__toast-container {
  width: 100%;
  max-width: 450px;
}

.Toastify__toast--info {
  background-color: #006987;
}

.Toastify__toast--success {
  background-color: #4caf50;
}
